import { GetStaticPropsContext } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import Page, { PageProps } from 'components/Page';
import Service from 'lib/cms/service';
import { Page as IPage } from 'interfaces/cms/page';
import { Preview } from 'components/common/Preview';
import nextI18nextConfig from '../../next-i18next.config';

function PageNotFound({ page, previewMode }: PageProps) {
  return (
    <>
      <Preview previewMode={previewMode} />
      <Page {...{ page }} />
    </>
  );
}

export async function getStaticProps({
  locale,
  preview,
}: GetStaticPropsContext) {
  const strapi = new Service();

  const [layout, settings] = await Promise.all([
    strapi.getLayout(locale),
    strapi.getSettings(locale),
  ]);

  const previewMode = Boolean(preview);

  let page: IPage;
  if (previewMode) {
    page = await strapi.getHomePagePreviewByLocale(locale);
  } else {
    page = await strapi.getPageBySlugAndLocale('page-not-found', locale);
  }

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'], nextI18nextConfig)),
      locale,
      layout,
      notifications: [],
      popup: false,
      settings,
      page,
      isMultipleLang: false,
      previewMode,
    },
    revalidate: 1,
  };
}

export default PageNotFound;
